import React from 'react'
import { Link } from 'react-router-dom';
import logo from './assets/images/logo.png';
import Account from './Header/Account'

// The Header creates links that can be used to navigate
// between routes.
class Header extends React.Component {

	render () {
		return (
			<header>
		    	<Link to="/"><img src={logo} alt="Solar Circle"/></Link>
				<Account />
			</header>
		)
	}


}

export default Header
