import React from 'react'

class Step1 extends React.Component { 
	constructor(props) {
	    super(props);
	    
	    this.handleInputChange = this.handleInputChange.bind(this);
	}
	  
	handleInputChange(event) {
		this.props.handleChange(event);
	}
	
	render() {
		return (
			<form onSubmit={this.handleSubmit}>
				<p>Introduce las medidas del tejado</p>
				
				<div className="inputRow">
					<label>
						Largo del tejado<br />
						<small>(Del canalón a la cresta)</small>
					</label>
					
					<div className="inputField">
						<input name="daklengte" className={this.props.errors['daklengte'] ? "error" : ""} type="text" value={this.props.data['daklengte']} onChange={this.handleInputChange} /> metros
					</div>
				</div>
				<div className="inputRow">
					<label>
						Ancho del tejado<br />
						<small>(A largo del canalón)</small>
					</label>
					
					<div className="inputField">
						<input name="dakbreedte" className={this.props.errors['dakbreedte'] ? "error" : ""} type="text" value={this.props.data['dakbreedte']} onChange={this.handleInputChange} /> metros
					</div>
				</div>
			</form>
			
		)
	}
}


export default Step1
