import React from 'react';
import loadingGif from '../assets/images/loading.gif'
import config from '../../config.json'
import Accordion from "./Accordion";

class Leads extends React.Component {

    componentDidMount() {
	    this.props.fetchFriends();
    }

	AanmeldButtonClick() {
		this.props.changePage('addFriend');
		this.props.GTMevent('button_click_vriend_aangemeld', 'Vrienden', 'Button Vriend aanmelden', 'Clicked');
	}

	RequestPayoutButtonClick() {
		this.props.changePage('requestPayout');
		this.props.GTMevent('button_click_vriend_aangemeld', 'Vrienden', 'Button Vriend aanmelden', 'Clicked');
	}

	render() {
		const {totalamount, totaluitgekeerd, payment_status} = this.props;
		const isLoading = this.props.isLoading;
		let totaltegoed = totalamount - totaluitgekeerd;
		let textuitkeren = '';
		let buttonuitkeren = '';

		if (isLoading) {
            return <LoadingDiv />;
        }

		if (payment_status !== config.PAYMENT_STATUS) {
			if (this.props.friends?.count > 0) {
				textuitkeren = <p className="text-center">En total has ganado {this.props.toCurrency(totalamount)}, de lo que {this.props.toCurrency(totaluitgekeerd)} ya está pagado.</p>
			} else {
				textuitkeren = <p className="text-center">No has traído amigos a Solar Circle todavía.</p>
			}

			if (totaltegoed > 0) {
				buttonuitkeren = <button className="button" onClick={() => this.RequestPayoutButtonClick()}>Pagar {totaltegoed} €</button>
				// buttonuitkeren = 'Neem contact op met De Zonnefabriek om het openstaande tegoed uit te keren.'
			}
		} else {
			textuitkeren = <p className="text-center">Ya has solicitado un pago. Hasta que no esté completo, no puedes solicitar un nuevo pago.</p>
		}

		return (
			<div>
				<p className="text-center">¡Comentando a tus amigos las ventajas de Solar Circle, ahora puedes ganar un mínimo de {this.props.toCurrency(this.props.current_reward)}!</p>
				<button className="button" onClick={() => this.AanmeldButtonClick()}>Registrar amigo</button>
                {this.props.friends?.aangedragen?.length > 0 && (
                    <Accordion
                        accordionActive={true}
                        accordionTitle="Amigos registrados"
                        accordionContent={this.props.friends?.aangedragen}
                    />
                )}
                {this.props.friends?.akkoord?.length > 0 && (
                    <Accordion
                        accordionActive={false}
                        accordionTitle="Aceptado"
                        accordionContent={this.props.friends?.akkoord}
                    />
                )}
				{this.props.friends?.uitbetaald?.length > 0 && (
					<Accordion
						accordionActive={false}
						accordionTitle="Pagado"
						accordionContent={this.props.friends.uitbetaald}
					/>
				)}
				{textuitkeren}
				{buttonuitkeren}
			</div>
		);
	}
}

class LoadingDiv extends React.Component {
    render() {
        return (
            <div className="LoadingDiv">
                <img src={loadingGif} alt="Cargando" />
            </div>
        )
    }
}

export default Leads
