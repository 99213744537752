import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import {isMobile} from 'react-device-detect';
import Opbrengst from './Opbrengst'
import Installaties from './Installaties'
import Dakcheck from './Dakcheck'
import Vrienden from './Vrienden'
import Account from './Account'
import Header from './Header'
import Footer from './Footer'

class Main extends React.Component {

	render () {
		return (

			<main>
		    	<div className="standard">
			        <Header />
			        <Switch>
			        	<Route
			                exact
			                path="/"
			                render={() => {
			                    return (
			                      this.props.isLoggedin ?
			                      <Redirect to="/installaties" /> : ''
			                    )
			                }}
			              />
				        <Route path='/installaties'
				        	render={(props) => (
								<>
									{
										isMobile && (
											<div className="headerWrapper">
												<h1>Arrastra la página hacia abajo para refrescar</h1>
											</div>
										)
									}
									<Installaties {...props}
												  user_name={this.props.user_name}
												  token={this.props.token}
												  setCache = {this.props.setCache}
												  getCache = {this.props.getCache}
												  clearCache = {this.props.clearCache}
												  checkToken={this.props.checkToken}
									/>
								</>
							)}
						/>
				        <Route path='/opbrengst'
				        render={(props) => (
							<>
								{
									isMobile && (
										<div className="headerWrapper">
											<h1>Arrastra la página hacia abajo para refrescar</h1>
										</div>
									)
								}
								<Opbrengst {...props}
										   user_name={this.props.user_name}
										   api_type={this.props.api_type}
										   token={this.props.token}
										   setCache = {this.props.setCache}
										   getCache = {this.props.getCache}
										   clearCache = {this.props.clearCache}
										   checkToken={this.props.checkToken}

								/>
							</>
							)}/>
				        <Route path='/dakcheck'
				        	render={(props) => (
								<>
									<Dakcheck {...props}
										user_name={this.props.user_name}
										token={this.props.token}
										clearCache={this.props.clearCache}
										GTMevent = {this.props.GTMevent}
										checkToken={this.props.checkToken}
									/>
								</>
							)}
				        />
				        <Route path='/vrienden'
				        	render={(props) => (
							    <>
									{
										isMobile && (
											<div className="headerWrapper">
												<h1>Arrastra la página hacia abajo para refrescar</h1>
											</div>
										)
									}
									<Vrienden {...props}
											  user_name={this.props.user_name}
											  token={this.props.token}
											  setCache = {this.props.setCache}
											  getCache = {this.props.getCache}
											  clearCache={this.props.clearCache}
											  GTMevent = {this.props.GTMevent}
											  checkToken={this.props.checkToken}
									/>
								</>
							)}
						/>
						<Route path='/account'
				        	render={(props) => (
							    <Account {...props}
									handleLogout={this.props.handleLogout}
								    user_name={this.props.user_name}
									fullname={this.props.fullname}
									address={this.props.address}
									email={this.props.email}
									token={this.props.token}
									GTMevent={this.props.GTMevent}
									GTMpageview={this.props.GTMpageview}
									checkToken={this.props.checkToken}
							    />
							)}
						/>
			        </Switch>
			        <Footer GTMpageview={this.props.GTMpageview} />
				</div>
			</main>
		)
	}

}

export default Main
