import React from 'react'
import Chart from './Chart/Chart'
import PullToRefresh from 'react-simple-pull-to-refresh';


class Opbrengst extends React.Component {

	componentDidMount() {
		let user_name = localStorage.getItem('user_name');
		let token = localStorage.getItem('token');

		this.props.checkToken(user_name, token);
	}

	handleRefresh = () => {
		new Promise( (resolve, reject) => {
			this.props.clearCache('getYield');
			resolve("Promise resolved successfully");
		});
	}

	render() {
		return (

			<div className="contentWrapper opbrengst">

				<PullToRefresh onRefresh={this.handleRefresh}>

					<div className="content">

						<h1>Beneficios</h1>

						<Chart
							setCache = {this.props.setCache}
							getCache = {this.props.getCache}
							api_type = {this.props.api_type}
						/>

					</div>

				</PullToRefresh>

			</div>

		);
	}
}

export default Opbrengst
