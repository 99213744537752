import React from 'react'
import InstallatieDetail from './Installaties/InstallatieDetail'
import PullToRefresh from 'react-simple-pull-to-refresh';

class Installaties extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            documents: [],
            isLoading: true
        };
    }

    setLoading = (value = false) => {
        this.setState({
            isLoading: value
        })
    }

    fetchInstallaties = () => {

        let username = localStorage.getItem('user_name')
        let token = localStorage.getItem('token')
        let url = process.env.REACT_APP_API_URL + '?function=getInstallations&user=' + username + '&token=' + token;

        var cachedResponse = this.props.getCache(url);

        if (cachedResponse) {
            this.setState({
                data: cachedResponse,
                isLoading: false,
                requestError: false
            })
        } else {
            fetch(url)
                .then(response => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        throw new Error('Response from API not ok')
                    }
                })
                .then((data) => {
                    if (data.Success !== true) {
                        throw new Error('Success != true in json');
                    } else {
                        this.setState({
                            data: data.Result
                        })
                    }
                })
                .then((data) =>
                    this.setState({
                        isLoading: true
                    }))
                .then((data) => {
                    this.setState({
                        isLoading: false
                    })
                    this.props.setCache({'url': url, 'response': this.state.data}, 'installations', null)
                }).catch((error) => {
                    this.setState({
                        requestError: true,
                        isLoading: false
                    });
                });
        }
    }

    componentDidMount() {
        this.fetchInstallaties();
        let user_name = localStorage.getItem('user_name');
        let token = localStorage.getItem('token');

        this.props.checkToken(user_name, token);
    }

    handleRefresh = () => {
        new Promise((resolve, reject) => {
            this.props.clearCache('getInstallations');
            resolve("Promise resolved successfully");
        });
    }

    render() {
        let fullname = localStorage.getItem('fullname')

        if (this.state.requestError) {
            return (
                <div className="contentWrapper installaties">
                    <PullToRefresh onRefresh={this.handleRefresh}>
                        <div className="content">
                            <h1>Bienvenido/a de nuevo, {fullname}</h1>
                            <p>Hay un error al cargar la página, prueba de nuevo más tarde.</p>
                        </div>
                    </PullToRefresh>
                </div>
            );
        } else {
            return (
                <div className="contentWrapper installaties">
                    <PullToRefresh onRefresh={this.handleRefresh}>
                        <div className="content">
                            <InstallatieDetail
                                InstallatieData={this.state.data}
                                documents={this.state.documents}
                                isLoading={this.state.isLoading}
                                setCache={this.props.setCache}
                                getCache={this.props.getCache}
                                clearCache={this.props.clearCache}
                                fullname={fullname}
                                fetchInstallaties={this.fetchInstallaties}
                                setLoading={this.setLoading}
                            />
                        </div>
                    </PullToRefresh>
                </div>
            );
        }
    }
}


export default Installaties
