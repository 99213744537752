import React from 'react'
import loadingGif from '../assets/images/loading.gif'

class ProcessFriend extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
            isLoading: true,
            data: {}
        }

	}

	handleInputChange(event) {
		this.props.handleChange(event);
	}

	processProspect() {

		let username = localStorage.getItem('user_name')
        let token = localStorage.getItem('token')
        let url = process.env.REACT_APP_API_URL + '?function=addProspect&user=' + username + '&token=' +  token ;

		var call = url +
			'&accountname=' + this.props.data.vriendnaam +
			'&email=' + this.props.data.vriendemail +
			'&telefoon=' + this.props.data.vriendtelefoon
			;

		fetch(call)
			.then(response => response.json())
	        .then(data =>
	        	this.setState({
                	data: data.Result,
					isLoading: false
                })
            ).then(data =>
	            this.processGTMevent()
            ).then(data =>
	            this.props.clearCache('getProspects', false)
            );

	}

	componentDidMount() {
		this.processProspect();
	}

	processGTMevent() {
		if (this.state.data.Error) {
			this.props.GTMevent('vriend_aangemeld_existing_lead', 'Vrienden', 'Vriend aangemeld', 'Existing lead');
		} else {
			this.props.GTMevent('vriend_aangemeld_success', 'Vrienden', 'Vriend aangemeld', 'Success');
		}
	}

	resetAddFriend = () => {
		this.props.resetAddFriend();
	}

	render() {

		const {isLoading} = this.state;
		const errors = {
			'Email already exists in VTiger': 'Ya conocemos a esta persona, nos pondremos en contacto con ella de nuevo. No podrás obtener la compensación por traer a un amigo.'
		}

		if (isLoading) {
            return <LoadingDiv />;
        }


		if (this.state.data.Error) {
			return <div className="error">
						<p>{errors[this.state.data.Error]}</p>
						<p><button className="backlink" onClick={() => this.props.changePage('addFriend')}>Atrás</button></p>
						<p><button className="backlink" onClick={() => this.resetAddFriend() }>Visión general</button></p>
					</div>
		} else {
			return <div>¡Gracias! Nos pondremos en contacto con él/ella y esperamos obtener una respuesta positiva. Puedes seguir el estado del proceso en la pestaña "Amigos".</div>
		}

	}
}

class LoadingDiv extends React.Component {
    render() {
        return (
            <div className="LoadingDiv">
                <img src={loadingGif} alt="Cargando" />
            </div>
        )
    }
}

export default ProcessFriend
