import React from 'react'

class AddFriend extends React.Component { 
	constructor(props) {
	    super(props);
	    
	    this.handleInputChange = this.handleInputChange.bind(this);
	}
	  
	handleInputChange(event) {
		this.props.handleChange(event);
	}
	
	render() {
		return (
			<div className="dakcheck">
			<form onSubmit={this.handleSubmit} className="stepVriend">
				<p>¡Registra al propietario/a de este tejado y contactamos con él/ella lo antes posible!</p>
				
				<div className="inputRow">
					<label>
						Nombre<br />
					</label>
					
					<div className="inputField">
						<input name="vriendnaam" className={this.props.errors['vriendnaam'] ? "error" : ""} type="text" value={this.props.data['vriendnaam']} onChange={this.handleInputChange} />
					</div>
				</div>
				<div className="inputRow">
					<label>
						Correo electrónico<br />
					</label>
					
					<div className="inputField">
						<input name="vriendemail" className={this.props.errors['vriendemail'] ? "error" : ""} type="text" value={this.props.data['vriendemail']} onChange={this.handleInputChange} /> 
					</div>
				</div>
				<div className="inputRow">
					<label>
						Teléfono<br />
					</label>
					
					<div className="inputField">
						<input name="vriendtelefoon" className={this.props.errors['vriendtelefoon'] ? "error" : ""} type="text" value={this.props.data['vriendtelefoon']} onChange={this.handleInputChange} /> 
					</div>
				</div>
			</form>
			<button className="button" onClick={() => this.props.changePage('processFriend') }>Enviar</button>
			<button href="#" className="backlink" onClick={() => this.props.changePage('overview') }>Atrás</button>
			
			</div>
		)
	}
}


export default AddFriend
